<template>
  <div class="super-admin-leads">
    <!-- Page header -->
    <div class="page-header">
      <h1 class="page-title">
        Super Admin - Leads
      </h1>
      <div class="actions">
        <!-- <a-button
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          @click.prevent="$router.push('/admin/reporting/create')"
          >Run Report</a-button
        > -->
      </div>
    </div>
    <!-- / Page header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Search Panel -->
    <div v-if="!isLoading" class="search-panel">
      <a-row :gutter="20" align="middle" justify="center" type="flex">
        <a-col :span="10">
          <a-form-item style="margin-bottom: 20px" label="Start Date">
            <a-date-picker style="width: 80%" size="large" v-model="start_date" />
          </a-form-item>
        </a-col>
        <a-col :span="10">
          <a-form-item style="margin-bottom: 20px" label="End Date">
            <a-date-picker style="width: 80%" size="large" v-model="end_date" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-button @click.prevent="searchLeads" class="btn-rounded" block icon="search" size="large"
            type="primary">Search</a-button>
        </a-col>
      </a-row>
    </div>
    <!-- / Search Panel -->

    <!-- Is Loading Search Results -->
    <div v-if="isLoadingSearchResults" class="loader loading-search-results">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading Search Results -->

    <!-- Search Results -->
    <div v-if="hasSearchBeenPerformed && !isLoadingSearchResults" class="search-results">
      <!-- No search results -->
      <a-alert v-if="searchResults.length == 0" type="info" message="No leads to show" />
      <!-- / No search results -->

      <!-- Results -->
      <a-row v-if="searchResults.length" :gutter="20" type="flex">
        <!-- {{  orderedSearchResults  }} -->
        <a-col :span="colSpan" v-for="(lead, i) in orderedSearchResults" :key="i">
          <lead-list-item @selected="selectLead(lead)" :tenant-id="tenantId" :lead="lead"></lead-list-item>
        </a-col>
      </a-row>
      <!-- / Results -->

      <!-- Selected Lead -->
      <selected-lead-modal :lead="selectedLead" @close-modal="selectLead(null)"></selected-lead-modal>
      <!-- / Selected Lead -->
    </div>
    <!-- / Search Results -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const _ = require("lodash");
// import leads from "../../api/leaads";
import LeadListItem from "./Leads/LeadListItem.vue";
import SelectedLeadModal from "./Leads/SelectedLeadModal.vue";
export default {
  name: "Leads",

  components: { LeadListItem, SelectedLeadModal },

  data() {
    return {
      windowWidth: window.innerWidth,
      selectedLead: null
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    // TODO: check platform admin
    if (this.$route.query.reset !== 'false') {
      this.reset();
      this.searchLeads();
    }
  },

  computed: {
    ...mapGetters("admin", {
      isLoading: "isLoading",
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
      myPresenceId: "myPresenceId",
    }),

    ...mapGetters("superAdminLeads", {
      startDate: "startDate",
      endDate: "endDate",
      hasSearchBeenPerformed: "hasSearchBeenPerformed",
      searchResults: "searchResults",
      isLoadingSearchResults: "isLoading",
    }),

    orderedSearchResults() {
      return _.orderBy(this.searchResults, 'captured');
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    start_date: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.setStartDate(val);
        this.searchLeads();
      },
    },

    end_date: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.setEndDate(val);
        this.searchLeads();
      },
    },
  },

  methods: {
    ...mapActions("superAdminLeads", {
      reset: "reset",
      setStartDate: "setStartDate",
      setEndDate: "setEndDate",
      searchLeads: "searchLeads",
    }),

    selectLead(lead) {
      this.selectedLead = lead;
    }
  },
};
</script>

<style lang="scss" scoped>
.super-admin-leads {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .search-panel {
    background: #fff;
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .admin-lead-list-item {
    margin-bottom: 15px;
  }

  .loading-search-results {
    margin-top: 25px;
  }

  .search-results {
    margin-top: 20px;
  }
}
</style>
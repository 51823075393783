<template>
  <div class="admin-lead-list-item" @click.prevent="$emit('selected')">
    <div class="left">
      <a-avatar size="large">{{ initials }}</a-avatar>
    </div>
    <div class="middle">
      <div class="name">
        {{ lead.firstName }} {{ lead.lastName }}
      </div>
      <div class="org">
        <a-icon type="team"></a-icon> {{ lead.organisation }}
      </div>
      <div class="contact-details">
        <div class="email">
          <a-icon type="mail"></a-icon>
          {{ lead.email }}
        </div>
        <div class="phone">
          <a-icon type="phone"></a-icon>
          {{ lead.phone }}
        </div>
      </div>
      <div class="captured">
        <a-icon type="clock-circle"></a-icon>
        {{ capturedAt }}
      </div>
    </div>
    <div class="right">
      <a-badge v-if="lead.demoRequested" count="Demo Requested"></a-badge>
      <a-badge v-if="lead.quoteRequested" count="Quote Requested"></a-badge>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lead'],
  computed: {
    initials() {
      let initials = '';

      if (this.lead.firstName) {
        initials = initials + this.lead.firstName[0];
      }

      if (this.lead.lastName) {
        initials = initials + this.lead.lastName[0];
      }

      return initials.toUpperCase();
    },

    capturedAt() {
      return window.moment(this.lead.captured).fromNow();
    }
  }
}
</script>

<style scoped lang="scss">
.admin-lead-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .middle {
    padding-left: 15px;
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .ant-badge {
    margin-left: 10px;
  }

  .org,
  .contact-details,
  .captured {
    margin-top: 5px;

    .anticon {
      margin-right: 4px;
    }
  }

  .contact-details div {
    display: inline-block;

    &:first-child {
      margin-right: 25px;
    }
  }
}
</style>
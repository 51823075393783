<template>
  <a-modal title="Lead Details" :footer="null" v-model="showModal" :centered="true">
    <div v-if="lead">

      <div class="property">
        <b>Date</b>
        {{ capturedAt }}
      </div>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>First Name</b>
            {{ lead.firstName ? lead.firstName : 'Not provided' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Last Name</b>
            {{ lead.lastName ? lead.lastName : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Email</b>
            {{ lead.email ? lead.email : 'Not provided' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Phone</b>
            {{ lead.phone ? lead.phone : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Organisation</b>
            {{ lead.organisation ? lead.organisation : 'Not provided' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Job Title</b>
            {{ lead.jobTitle ? lead.jobTitle : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Organisation Type</b>
            {{ lead.organisationType ? lead.organisationType : 'Not provided' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Number of Schools</b>
            {{ lead.numberOfSchools ? lead.numberOfSchools : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Demo Requested</b>
            {{ lead.demoRequested ? 'Yes' : 'No' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Quote Requested</b>
            {{ lead.quoteRequested ? 'Yes' : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Keep Me Updated</b>
            {{ lead.keepMeUpdated ? 'Yes' : 'No' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Pupils on Roll</b>
            {{ lead.pupilsOnRoll ? lead.pupilsOnRoll : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Origin</b>
            {{ lead.origin ? lead.origin : 'Not provided' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Affiliate</b>
            {{ lead.affiliate ? lead.affiliate : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="property">
            <b>Lead Story</b>
            {{ lead.leadStory ? lead.leadStory : 'Not provided' }}
          </div>
        </a-col>
        <a-col :span="12">
          <div class="property">
            <b>Contact Methods</b>
            {{ lead.contactMethods ? lead.contactMethods : 'Not provided' }}
          </div>
        </a-col>
      </a-row>

      <!-- <div class="property">
        <b>Oranisation Type</b>
        {{ lead.organisationType ? lead.organisationType : 'Not provided' }}
      </div> -->

      <div class="property">
        <b>Notes</b>
        {{ lead.notes ? lead.notes : 'Not provided' }}
      </div>

      <!-- {{ lead }} -->

    </div>
  </a-modal>
</template>

<script>
export default {
  props: ['lead'],
  computed: {
    showModal: {
      get() {
        return this.lead !== null;
      },
      set(val) {
        if (!val) {
          this.$emit('close-modal');
        }
      }
    },

    capturedAt() {
      if (this.lead) {
        return window.moment(this.lead.capturedAt).format('dddd Do MMMM Y hh:mm A');
      }
      return null;
    }
  }
}
</script>

<style scoped lang="scss">
.property {
  b {
    display: block;
    // margin-bottom: 3px;
  }

  margin-bottom: 13px;
}
</style>